import $ from "jquery";

export default{
    install: (app)=>{

        /**
         * @class
         * @name Capi
         * @returns {Promise}
         */
        class Api{
            /**
             *
             * @param path
             * @param data
             * @return {Promise<object|array>}
             */
            get(path, data = null){
                return new Promise((resolve, reject)=>{
                    $.ajax({
                        url: process.env.VUE_APP_API+path,
                        data: {data},
                        dataType: 'json',
                        type: 'GET',
                        accept: 'application/json; charset=utf-8',
                        headers:{
                            Accept: 'application/json; charset=utf-8',
                            'X-API-KEY': process.env.VUE_APP_API_KEY
                        },
                        crossDomain: true,
                        success: function (data) {
                            resolve(data.message)
                        },
                        error: function ({responseJSON: error}) {
                            reject(error);
                        }
                    });
                })
            }

            /**
             * PUT data to API (update)
             * @dynamic
             * @const
             * @param attrs
             * @param updateData
             * @property response.responseJSON
             * @return Promise
             */
            update(attrs, updateData = null){
                return new Promise((resolve, reject) => {
                    let pack = app.utilities.createPackObject(attrs, updateData);
                    let url = ApiRouter.get(
                        {
                            ...attrs,
                            path: !attrs['path'] ? 'update' : attrs['path']
                        }
                    );

                    $.ajax({
                        url: url,
                        data: pack,
                        dataType: 'json',
                        type: 'PUT',
                        xhrFields: {
                            withCredentials: true
                        },
                        crossDomain: true,
                        success: function (data) {
                            resolve(data.message);
                        },

                        error: function ({responseJSON: error}) {
                            reject(error);
                        }
                    });
                });
            }

            /**
             * POST data to API (create)
             * @param path
             * @param data
             * @property response.responseJSON
             * @return Promise
             */
            create(path, data= null){
                return new Promise((resolve, reject) => {

                    $.ajax({
                        url: process.env.VUE_APP_API+path,
                        data: {data},
                        dataType: 'json',
                        type: 'POST',
                        accept: 'application/json; charset=utf-8',
                        headers:{
                            Accept: 'application/json; charset=utf-8',
                            'X-API-KEY': process.env.VUE_APP_API_KEY,
                            'X-EVENT-ID': process.env.VUE_APP_EVENT_ID
                        },
                        crossDomain: true,
                        success: function (response) {
                            resolve(response.message);
                        },

                        error: function ({responseJSON: error}) {
                            reject(error);
                        }
                    });
                });

            }

            /**
             * @dynamic
             * @const
             * @param attrs
             * @param updateData
             * @property response.responseJSON
             * @return Promise
             */
            delete(attrs, updateData = null){
                return new Promise((resolve, reject) => {
                    let pack = app.utilities.createPackObject(attrs, updateData);
                    let url = ApiRouter.get(
                        {
                            ...attrs,
                            path: !attrs['path'] ? 'delete' : attrs['path']
                        }
                    );

                    $.ajax({
                        url: url,
                        data: pack,
                        dataType: 'json',
                        type: 'DELETE',
                        xhrFields: {
                            withCredentials: true
                        },
                        crossDomain: true,
                        success: function (data) {
                            resolve(data.message);
                        },

                        error: function ({responseJSON: error}) {
                            reject(error);
                        }
                    });
                });
            }

        }

        app.api = new Api;
        app.provide('Api', new Api)
    }
}
