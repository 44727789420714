<script setup>

import {ref, watchEffect} from "vue";

const props = defineProps([
    'count'
])

const images = ref([])
const count = ref()

watchEffect(()=>{
    count.value = props.count
    images.value = []
    for(let i=0;i<8;i++){
        if(i<=count.value) images.value.push(require('@/assets/img/pins/progress_on.png'))
        else images.value.push(require('@/assets/img/pins/progress_off.png'))
    }
})

</script>

<template>
    <div class="progress-bar">
        <img v-for="img in images" :src="img">
    </div>

</template>

<style scoped lang="scss">

.progress-bar{
    margin-top: 0;
    display: flex;
    flex-flow: row;
    gap: 6%;
    width: 100%;
    justify-content: center;
    img{
        margin-top: 2rem;
        width: 6%;
    }
}

</style>
