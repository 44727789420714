export const buttons = {
    left:{
        default: require('@/assets/img/arrows/left_off.png'),
        hover: require('@/assets/img/arrows/left_on.png')
    },
    right:{
        default: require('@/assets/img/arrows/right_off.png'),
        hover: require('@/assets/img/arrows/right_on.png')
    },
    back:{
        default: require('@/assets/img/buttons/back_off.png'),
        hover: require('@/assets/img/buttons/back_on.png'),
        disabled: require('@/assets/img/buttons/back_ghost.png')
    },
    end:{
        default: require('@/assets/img/buttons/end_off.png'),
        hover: require('@/assets/img/buttons/end_on.png')
    },
    ok:{
        default: require('@/assets/img/buttons/ok_off.png'),
        hover: require('@/assets/img/buttons/ok_on.png'),
        disabled: require('@/assets/img/buttons/ok_ghost.png')
    },
    draw:{
        default: require('@/assets/img/buttons/draw_off.png'),
        hover: require('@/assets/img/buttons/draw_on.png'),
    },
    again:{
        default: require('@/assets/img/buttons/again_off.png'),
        hover: require('@/assets/img/buttons/again_on.png'),
        disabled: require('@/assets/img/buttons/again_disabled.png'),
    },
    pin:{
        default: require('@/assets/img/pins/font_off.png'),
        selected: require('@/assets/img/pins/font_on.png'),
    }
}
