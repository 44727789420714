<template>

    <div class="content">
        <div class="poster-container">
            <img
                class="poster"
                :src="posterUrl"
            >
        </div>

        <div
            v-if="posterQrUrl"
            class="claims-qr"
        >
            <div class="claims">
                <div class="congrats">
                    GGWP!
                </div>

                <div class="unique-game">
                    Tvoje hra je jedinečná.
                </div>

                <div class="epilogue-container">
                    <div class="epilogue">
                        Nikdo nevytvořil stejnou. Měl bys to zkusit u jednoho z českých vývojářských studií.
                    </div>
                </div>
            </div>
            <img
                :src="posterQrUrl"
                class="qr"
            >
        </div>


    </div>


    <div class="footer-panel">

       <img
            class="button-back"
            @click="back()"
            :src="buttonsSrc.back"
        >

        <img
            class="button-again"
            @click="again()"
            :src="buttonsSrc.again"
        >

        <img
            class="button-end"
            @click="endGame"
            :src="buttonsSrc.end"
        >
    </div>

</template>

<script setup>

import {inject, reactive, ref, watch, watchEffect} from "vue";
import {buttons} from '@/app/Variables'
import {buttonClickSpeed} from "@/app/Settings";

/** @type Env */
const env = inject('env')

/** @type Api */
const api = inject('Api')

/** @type Dialog */
const dialog = inject('Dialog')

let data = {}

const posterUrl = ref(process.env.VUE_APP_API+'/public/src/general/loading_fin.gif')
const posterQrUrl = ref()

const buttonsSrc = reactive({
    left: buttons.left.default,
    right: buttons.right.default,
    end: buttons.end.default,
    ok: buttons.ok.default,
    back: buttons.back.default,
    draw: buttons.draw.default,
    again: buttons.again.default
})

watchEffect(()=>{
    for(let [componentId, asset] of Object.entries(env.wizard.selectedAsset)){
        data[componentId] = asset
    }

    api.create('/poster/'+env.game.id, data)
        .then((response)=>{
            console.log('response', response)
            console.log('poster url', process.env.VUE_APP_API+'/public/posters/'+response['item']['path'])
            posterUrl.value = process.env.VUE_APP_API+'/public/posters/'+response['item']['path']+'.png'
            posterQrUrl.value = process.env.VUE_APP_API+'/public/posters/'+response['item']['path']+'-qr-code.png'
        })
        .catch((e)=>{
            console.log('e', e)
            dialog.error('Chyba při vytváření hry. <br><br> Zkus to znovu.')
        })
})

function back(){
    buttonsSrc.back = buttons.back.hover
    setTimeout(()=>{
        env.page = 'wizard'
        env.wizard.currentComponentId = env.configuration.component[env.configuration.component.length-1].id
        buttonsSrc.back = buttons.back.default
    }, buttonClickSpeed)
}

function again(){
    buttonsSrc.again = buttons.again.hover
    setTimeout(()=>{
        buttonsSrc.again = buttons.again.default
    }, buttonClickSpeed)
    dialog.prompt('Opravdu chceš <br> svoji hru <br> sestavit znovu?')
    watch((env.dialog), function({hook}){
        if(hook){
            env.page = 'wizard'
            env.wizard.currentComponentId = env.configuration.component[0].id
        }
        else{
            dialog.close()
        }
    })
}

function home(){
    env.page = 'home'
}

function endGame(){
    buttonsSrc.end = buttons.end.hover
    setTimeout(()=>{
        buttonsSrc.end = buttons.end.default
    }, buttonClickSpeed)
    dialog.prompt('Opravdu chceš <br>' +
        'spustit novou hru?')
    watch((env.dialog), function({hook}){
        if(hook){
            env.page = 'home'
        }
        else{
            dialog.close()
        }
    })
}

</script>

<style lang="scss" scoped>

.content{
    flex: 1;
    display: flex;
    flex-flow: column;
    align-items: center;

    .poster-container{
        position: relative;
        width: 80%;

        .poster{
            width: 100%;

        }
    }


}

.claims-qr{
    width: 75%;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    container-name: claims-qr;
    container-type: inline-size;

    .claims{
        flex: 1;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;

        .congrats{
            font-size: 36px;
            color: #ffffff;
            font-family: Joystick, sans-serif;
        }

        @container claims-qr (width > 700px) {
            .congrats {
                font-size: 50px;
            }
        }

        .unique-game{
            margin-top: 0.5rem;
            font-size: 20px;
            color: #ffffff;
            font-family: InterExtraBold, sans-serif;
            text-align: left;
        }

        @container claims-qr (width > 700px) {
            .unique-game {
                font-size: 40px;
            }
        }

        .epilogue-container{
            width: 100%;
            text-align: left;

            .epilogue{
                display: inline-block;
                width: 85%;
                margin-top: 0.5rem;
                font-size: 14px;
                color: #ffffff;
                font-family: InterRegular, sans-serif
            }
        }

        @container claims-qr (width > 700px) {
            .epilogue {
                font-size: 25px;
            }
        }
    }

    .qr{
        width: 30%;
        aspect-ratio: 652/761;
    }
}



</style>
