<template>
    <div class="main-frame">
        <component :is="env.page"/>
        <Dialog
            v-if="env.dialog"
        />
    </div>

    <div id="preload">
        <img
            v-if="imgPreload.length"
            v-for="img in imgPreload"
            :src="img"
        >
        <img
            :src="require('@/assets/img/poster-frame.png')"
            rel="preload"
        >
        <img
            :src="require('@/assets/img/poster-frame.png')"
            rel="preload"
        >
    </div>

</template>

<script setup>
import {inject, ref} from "vue";
import Dialog from "@/components/Dialog.vue";

/** @type {Env} */
const env = inject('env')

/** @type {Api} */
const api = inject('Api')

const imgPreload = ref([])

api.get('/configuration')
    .then((data)=>{
        env.configuration = data
        preloadImages()
    })
    .catch((e)=>{
        console.log('e', e)
    })

function preloadImages(){
    console.log('preloading images')
    env.configuration.asset.forEach(/** @type SmartAsset */(asset)=>{
        /** @type SmartComponent */
        let component = env.configuration.component.find(component=>component.id===asset.componentId)
        let picker = component.hasPicker?'picker/':''
        let url = process.env.VUE_APP_API+`/public/src/components/${component.id}/${picker}${asset.path}`
        if(asset.colors){
            let colors = asset.colors.split(',')
            asset.colors.split(',').forEach(color=>imgPreload.value.push(`${url}-${color}.png`))
        }
        else{
            imgPreload.value.push(`${url}.png`)
        }

        imgPreload.value.push(process.env.VUE_APP_API+'/public/src/general/loading_fin.gif')

    })
}


</script>

<style lang="scss">
@font-face {
    font-family: Abolition;
    src: url('@/assets/fonts/abolition/AbolitionTest-Regular.otf') format('opentype');
}

@font-face {
    font-family: InterRegular;
    src: url('@/assets/fonts/inter/Inter-Regular.ttf');
}

@font-face {
    font-family: InterExtraBold;
    src: url('@/assets/fonts/inter/Inter-ExtraBold.ttf');
}

@font-face {
    font-family: InterLight;
    src: url('@/assets/fonts/inter/Inter-Light.ttf');
}

@font-face {
    font-family: InterExtraLight;
    src: url('@/assets/fonts/inter/Inter-ExtraLight.ttf');
}

@font-face {
    font-family: Joystick;
    src: url('@/assets/fonts/joystick/joystix_monospace.otf') format('opentype');
}

@font-face {
    font-family: LitterLover;
    src: url('@/assets/fonts/litter_lover/LitterLover-Bold.ttf');
}

@font-face {
    font-family: GrapeSoda;
    src: url('@/assets/fonts/grape_soda/GrapeSoda.ttf');
}

@font-face {
    font-family: Upheaval;
    src: url('@/assets/fonts/upheaval/UpheavalPro.otf')  format('opentype');
}

html,
body{
    width: 100%;
    height: 100%;
    margin: 0;
}

#app {
    background: white;
    box-sizing: border-box;
    resize: horizontal;
    overflow: auto;
    max-width: 100%;
    height: calc(100vh);

    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

    display: flex;
    align-items: center;
    justify-content: center;


    .main-frame{
        position: relative;
        aspect-ratio: 9 / 16;
        height: 100%;
        background-image: url('~@/assets/img/bg.png');
        background-size: cover;
        display: flex;
        flex-flow: column;
        justify-items: center;
        align-items: center;
        container-name: main-frame;
        container-type: inline-size;

        .content{
            flex: 1;
        }

        .footer-panel{
            margin: 1rem 1.5rem 1.5em;
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: center;
            position: relative;

            .button-back{
                width: 17%;
            }

            .button-end{
                width: 15%;
            }

            .button-ok{
                width: 25%;
            }

            .button-again{
                width: 31%;
            }
        }

    @container main-frame (width > 700px) {
        .headline{
            flex-basis: 15rem;
        }

    }

    }



    #preload{
        display: none;
    }
}

button{
    text-transform: uppercase;
    &:hover{
        cursor: pointer;
    }
}

</style>
