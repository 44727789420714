import {createApp, reactive} from 'vue'
import App from './App.vue'
import Views from "@/app/Views";
import Plugins from "@/app/Plugins";

let app = createApp(App);

/**
 * @type {Env}
 */
const env =  reactive({
    appName: 'CS.app',
    configuration: null,
    dialog: false,
    page: 'home',
    game: null,
    wizard: {
        currentComponentId: null,
        selectedAsset: {}
    }

})


app.provide('env', env)
app.env = env;

app.use(Views)
app.use(Plugins)

app.mount('#app');
