export const pages = [
    'home',
    'wizard',
    'result'
];

export const wizardSteps = [
    'space',
    'hero',
    'heroWeapon',
    'minion',
    'enemy',
    'enemyWeapon',
    'gameName'
];

export const buttonClickSpeed = 100



