
import Home from "@/views/Home.vue";
import Rules from "@/views/Rules.vue"
import Wizard from "@/views/Wizard.vue"
import Result from "@/views/Result.vue"

export default{
    install: (app)=>{
        app.component('Home', Home)
        app.component('Rules', Rules)
        app.component('Wizard', Wizard)
        app.component('Result', Result)
    }
}
