import Api from "@/plugins/Api";
import Dialog from "@/plugins/Dialog";

export default{

    install:(app)=>{
        app.use(Api)
        app.use(Dialog)
    }

}
