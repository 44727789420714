<script setup>
import {ref, watchEffect} from "vue";

const props = defineProps([
    'colors',
    'selectedColor'
])

defineEmits([
    'selectColor'
])

const palette = ref({})
const count = ref()
const selectedColor = ref()

watchEffect(()=>{
    count.value = props.colors?.length
    palette.value = {}
    if(props.colors){
        props.colors.forEach((color)=>{
            palette.value[color] = {
                default: require('@/assets/img/palettes/'+color+'_off.png'),
                selected: require('@/assets/img/palettes/'+color+'_on.png')
            }
        })
    }
})

function selectColor(color){
    props.selectedColor = color
}
</script>

<template>
    <div class="color-picker">
        <img
            v-if="colors"
            v-for="[color,paths] in Object.entries(palette)"
            :alt="color"
            :src="props.selectedColor===color?paths.selected:paths.default"
            @click="$emit('selectColor', color)"
        >
        <img
            v-else
            :src="require('@/assets/img/palettes/placeholder.png')"
        >
    </div>
</template>

<style scoped lang="scss">
.color-picker{
    margin-top: 5%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    gap: 5%;
    img{
        width: 15%;
    }
}
</style>
