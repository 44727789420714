<template>
    <div class="home">
        <div class="level-up">
            <img src="@/assets/img/level-up.png">
        </div>
        <div class="headline">
            <img class="logo" :src="require('@/assets/img/logo.png')">
            <div class="h2-container"><h2>Nech se vtáhnout do vývoje!</h2></div>
            <div class="h3-container"><h3>Zvol jednotlivé komponenty hry krok<br> po kroku a počkej na výsledek.</h3></div>
        </div>
        <img
            class="start"
            :src="startButtonSrc"
            @click="start"
        >

        <img
            class="footer-logo"
            :src="require('@/assets/img/by-cs.png')"
        >
    </div>

</template>

<script setup>

import {inject, ref, watchEffect} from "vue";
import {buttons} from "@/app/Variables";
import {buttonClickSpeed} from "@/app/Settings";

/** @type Env */
const env = inject('env')

/** @type {Api} */
const api = inject('Api')

const startButton = {
    default: require('@/assets/img/buttons/start_off.png'),
    hover: require('@/assets/img/buttons/start_on.png')
}

const startButtonSrc = ref(startButton.default)

watchEffect(()=>{
    env.wizard.selectedAsset = {}
    env.wizard.currentComponentId = null
})


function start(){
    startButtonSrc.value = startButton.hover
    setTimeout(()=>{
        startButtonSrc.value = startButton.default
    }, buttonClickSpeed)
    api.create('/game')
        .then((response)=>{
            env.game = response.item
            env.page = 'wizard'
        })
        .catch((e)=>{
            console.log('e', e)
        })
}

</script>

<style lang="scss" scoped>


.home{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .level-up{
        margin-top: -7%;

        img{
            width: 35%;
        }
    }

    .headline{
        color: #ffffff;
        container-type: normal;
        container-name: sidebar;

        .logo{
            width: 90%;
        }

        h1{
            font-family: Abolition, sans-serif;
            text-transform: uppercase;
            font-weight: 100;
            margin-top: 0;
            margin-bottom: 5vh;
        }

        .h2-container{
            width: 100%;
            container-name: h2-container;
            container-type: inline-size;

            h2{
                font-family: InterExtraBold, sans-serif;
                font-size: 25px;
            }

            @container h2-container (width > 700px) {
                h2 {
                    font-size: 50px;
                }
            }
        }

        .h3-container{

            width: 100%;
            container-name: h3-container;
            container-type: inline-size;

            h3{
                font-family: InterLight, sans-serif;
                font-size: 15px;
            }

            @container h3-container (width > 700px) {
                h3 {
                    font-size: 30px;
                }
            }
        }




    }



    .start{
        width: 45%;
        margin-top: -12%;
    }

    .footer-logo{
        width: 30%;
        margin-bottom: -7%;
    }
}


</style>
