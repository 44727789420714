<script setup>

import {inject} from "vue";

/** @type {Env} */
const env = inject('env')

function yes(){
    env.dialog.hook = true
    env.dialog = null
}

function no(){
    env.dialog.hook = false
}

function close(){
    env.dialog = null
}

</script>

<template>
    <div class="dialog">
        <div
            class="inner"
            v-bind:style="{ backgroundImage: 'url(' + require('@/assets/img/dialog-bg.png') + ')' }"
        >
            <div class="message" v-html="env.dialog.message">
            </div>
            <div v-if="env.dialog.type==='prompt'" class="buttons">
                <div @click="yes">ANO</div>
                <div @click="no">NE</div>
            </div>
            <div v-else class="buttons">
                <div @click="close">ZAVŘÍT</div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

.dialog{
    font-family: Joystick, sans-serif;
    font-size: 0.8rem;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0,0,0,0.45);

    .inner{
        width: 50%;
        background-size: contain;
        background-repeat: no-repeat;
        aspect-ratio: 513/423;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        gap: 1rem;
        container-name: inner;
        container-type: inline-size;

        .message{
            text-align: left;
        }

        @container inner (width > 500px) {
            .message {
                font-size: 25px;
                margin-left: 2rem;
                margin-right: 2rem;
            }
        }

        .buttons{
            font-size: 18px;
            width: 100%;
            display: flex;
            flex-flow: row;
            justify-content: center;
            gap: 2rem;
            div{
                &:hover{
                    cursor: pointer;
                }
            }
        }

        @container inner (width > 500px) {
            .buttons {
                font-size: 30px;
                margin-top: 2rem;
            }
        }
    }
}

</style>