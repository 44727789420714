<template>
    <div>
        <h1>Pravidla hry</h1>
        <span>Lorem imsum pravidla hry text</span>
    </div>

    <div class="footer">
        <button
            @click="back"
        >
            ZPET
        </button>
    </div>
</template>

<script setup>

import {inject} from "vue";

/** @type Env */
const env = inject('env')

function back(){
    env.page = 'home'
}

</script>

<style lang="scss" scoped>

</style>


