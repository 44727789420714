<template>

    <ProgressBar
        :count="getComponentIndex('count')"
    />

    <div class="headline">
        <h1>{{ getComponentCto() }}</h1>
    </div>

    <div class="default" v-if="getComponentType()==='default'||getComponent().colors">
        <div class="selector">
            <img
                class="arrow left"
                :src="buttonsSrc.left"
                @click="previousAsset"
            >
            <!--            <div
                            v-bind:style="{ backgroundImage: 'url(' + getCurrentAssetFullPath() + ')' }"
                            class="asset-img-container">
                        </div>-->
            <img
                class="asset"
                :src="getCurrentAssetFullPath()"
            >
            <img
                class="arrow right"
                :src="buttonsSrc.right"
                @click="nextAsset"
            >
        </div>
        <div class="asset-name">
            <span>
                {{ getCurrentAssetName() }}
            </span>
        </div>

        <ColorPicker
            v-if="hasComponentColorPicker()||true"
            :colors="getCurrentAssetColors()"
            :selectedColor="getSelectedAsset().selectedColor"
            @selectColor="selectColor"
        />
    </div>
    <div class="game-name" v-else>
        <img
            class="draw"
            :src="buttonsSrc.draw"
            @click="draw()"
        >
        <div class="carousels">
            <div class="carousel" v-for="component in env.configuration['game_name_component']">
                <span>{{getGameNameAsset(component.id)}}</span>
                <img
                    :src="allGameNameComponentsAreSelected()?buttonsSrc.again[component.id]:buttons.again.disabled"
                    @click="allGameNameComponentsAreSelected()?draw(component.id):{}">
            </div>
        </div>

        <div class="fonts">
            <div
                class="font"
                v-for="font in env.configuration.font"
            >
                <span :style="{fontFamily: font['font_name']}">FONT</span>
                <img
                    :src="env.wizard.selectedAsset['font']['font_id']===font['font_id']?buttons.pin.selected:buttons.pin.default"
                    @click="selectFont(font['font_id'])"
                >
            </div>
        </div>
    </div>


    <div class="footer-panel">

        <img
            class="button-back"
            @click="isBackButtonActive()?previousComponent():{}"
            :src="isBackButtonActive()?buttonsSrc.back:buttons.back.disabled"
        >

        <img
            class="button-ok"
            @click="isOKButtonActive()?nextComponent():{}"
            :src="isOKButtonActive()?buttonsSrc.ok:buttons.ok.disabled"
        >

        <img
            class="button-end"
            @click="endGame"
            :src="buttonsSrc.end"
        >
    </div>

</template>

<script setup>

import {inject, reactive, ref, watch, watchEffect} from "vue";
import ProgressBar from "@/components/ProgressBar.vue";
import ColorPicker from "@/components/ColorPicker.vue";
import {buttons} from '@/app/Variables'
import {buttonClickSpeed} from "@/app/Settings";

/** @type Env */
const env = inject('env')

/** @type Dialog */
const dialog = inject('Dialog')

const buttonsSrc = reactive({
    left: buttons.left.default,
    right: buttons.right.default,
    end: buttons.end.default,
    ok: buttons.ok.default,
    back: buttons.back.default,
    draw: buttons.draw.default,
    again: {
        noun: buttons.again.default,
        adjective: buttons.again.default,
        tag: buttons.again.default
    }
})

let selectedFont = ref()

watchEffect(()=>{
    if(!env.wizard.currentComponentId) env.wizard.currentComponentId = env.configuration.component[0]['id']
    if(!env.wizard.selectedAsset[env.wizard.currentComponentId] && getComponentType()!=='gameName') env.wizard.selectedAsset[env.wizard.currentComponentId] = env.configuration.asset.filter(asset=>asset.componentId===env.wizard.currentComponentId)[0]
    if(hasComponentPicker() && !getSelectedAsset().selectedColor && getCurrentAssetColors()) getSelectedAsset().selectedColor = getCurrentAssetColors()[0]
    selectedFont.value = env.wizard.selectedAsset['font']?env.wizard.selectedAsset['font']['font_name']:null
})

env.wizard.selectedAsset['font'] = env.configuration['font'][0]

function getGameNameAsset(componentId){
    if(!env.wizard.selectedAsset[componentId]) return '-'
    switch(componentId){

        case 'adjective':
            let nounAsset = env.wizard.selectedAsset['noun']
            let gender = nounAsset['masculine']?'masculine':nounAsset['feminine']?'feminine':'neutral'
            return env.wizard.selectedAsset['adjective'][gender]

        case 'noun':
            let asset = env.wizard.selectedAsset['noun']
            return asset['masculine']?asset['masculine']:asset['feminine']?asset['feminine']:asset['neutral']

        case 'tag':
            return env.wizard.selectedAsset['tag']['name']

    }
}

function selectColor(color){
    getSelectedAsset().selectedColor = color
}

function isOKButtonActive(){
    return (getComponentType() !== 'gameName' || allGameNameComponentsAreSelected())
}

function isBackButtonActive(){
    return getComponentIndex() !== 0
}

function draw(componentId = null, autoClick = null){
    if(componentId !== null){
        if(!autoClick){
            buttonsSrc.again[componentId] = buttons.again.hover
            setTimeout(()=>{
                buttonsSrc.again[componentId] = buttons.again.default
            }, buttonClickSpeed)
        }
        let items = env.configuration['game_name_asset'].filter(asset=>asset['componentId']===componentId)
        let count = items.length
        let drawIndex = Math.floor(Math.random() * count)
        let item = items[drawIndex];
        if(!env.wizard.selectedAsset[componentId]||env.wizard.selectedAsset[componentId].id!==item.id){
            item = items[drawIndex];
        }
        else{

            while(item.id===env.wizard.selectedAsset[componentId].id){
                drawIndex = Math.floor(Math.random() * count)
                item = items[drawIndex];
            }
        }
        env.wizard.selectedAsset[componentId] = item
    }
    else{
        buttonsSrc.draw = buttons.draw.hover
        setTimeout(()=>{
            buttonsSrc.draw = buttons.draw.default
        }, buttonClickSpeed)
        env.configuration['game_name_component'].forEach(component=>draw(component.id, true))
    }
}

function allGameNameComponentsAreSelected(){
    return env.configuration['game_name_component'].every(component=>env.wizard.selectedAsset[component.id])
}

function getComponentAssets(){
    return env.configuration.asset.filter(asset=>asset['componentId']===env.wizard.currentComponentId)
}

function getCurrentAsset(){
    return env.wizard.selectedAsset[env.wizard.currentComponentId]
}

function getCurrentAssetIndex(){
    return getComponentAssets().findIndex(asset=>asset.id===getCurrentAsset().id)
}

function getCurrentAssetName(){
    return getCurrentAsset().name
}

function getCurrentAssetPath(){
    return hasComponentPicker()?'picker/'+getCurrentAsset().path:getCurrentAsset().path
}

function getCurrentAssetColors(){
    let colors = getCurrentAsset().colors
    return colors?colors.split(','):null
}

function getCurrentAssetFullPath(){
    return getCurrentAsset().colors
        ?process.env.VUE_APP_API+'/public/src/components/'+getComponentId()+'/'+getCurrentAssetPath()+'-'+getSelectedAsset().selectedColor+'.png'
        :process.env.VUE_APP_API+'/public/src/components/'+getComponentId()+'/'+getCurrentAssetPath()+'.png'
}
function previousAsset(){
    buttonsSrc.left = buttons.left.hover
    setTimeout(()=>{
        buttonsSrc.left = buttons.left.default
    }, buttonClickSpeed)
    let currentAssetIndex = getCurrentAssetIndex()
    if(currentAssetIndex===0) selectLastAsset()
    else selectAsset(currentAssetIndex-1)
}

function nextAsset(){
    buttonsSrc.right = buttons.right.hover
    setTimeout(()=>{
        buttonsSrc.right = buttons.right.default
    }, buttonClickSpeed)
    let currentAssetIndex = getCurrentAssetIndex()
    if(currentAssetIndex===getComponentAssets().length-1) selectFirstAsset()
    else selectAsset(currentAssetIndex+1)
}

function selectLastAsset(){
    selectAsset(getComponentAssets().length - 1)
}

function selectFirstAsset(){
    selectAsset(0)
}

function selectAsset(index){
    env.wizard.selectedAsset[env.wizard.currentComponentId] = getComponentAssets()[index]
}

function getSelectedAsset(){
    return env.wizard.selectedAsset[env.wizard.currentComponentId]
}

function getComponent(){
    return env.configuration.component.find(component=>component.id===env.wizard.currentComponentId)
}

function getComponentIndex(){
    return env.configuration.component.findIndex(component=>component.id===env.wizard.currentComponentId)
}

function nextComponent(){
    buttonsSrc.ok = buttons.ok.hover
    if(getComponentIndex()+1===env.configuration.component.length) env.page = 'result'
    else env.wizard.currentComponentId = env.configuration.component[getComponentIndex()+1].id
    setTimeout(()=>{
        buttonsSrc.ok = buttons.ok.default
    }, buttonClickSpeed)
}

function previousComponent(){
    buttonsSrc.back = buttons.back.hover
    setTimeout(()=>{
        buttonsSrc.back = buttons.back.default
    }, buttonClickSpeed)
    if(getComponentIndex()===0) env.page = 'home'
    else env.wizard.currentComponentId = env.configuration.component[getComponentIndex()-1].id

}

function getComponentCto(){
    return getComponent()['cto']
}

function getComponentType(){
    return getComponent()['type']
}

function hasComponentColorPicker(){
    return getComponent()['component_has_color_picker']
}

function hasComponentPicker(){
    return getComponent()['component_has_picker']
}

function getComponentId(){
    return getComponent()['component_id']
}

function selectFont(fontId){
    env.wizard.selectedAsset['font'] = env.configuration['font'].find(font=>font['font_id']===fontId)
}

function endGame(){
    buttonsSrc.end = buttons.end.hover
    setTimeout(()=>{
        buttonsSrc.end = buttons.end.default
    }, buttonClickSpeed)
    dialog.prompt('Opravdu chceš \n' +
        'zahodit dosavadní \n' +
        'práci na vývoji?')
    watch((env.dialog), function({hook}){
        if(hook){
            env.page = 'home'
        }
        else{
            dialog.close()
        }
    })
}

</script>

<style lang="scss" scoped>

    .headline{
        display: flex;
        flex-basis: 9rem;
        width: 100%;
        font-family: InterExtraBold, sans-serif;
        color: #ffffff;
        container-name: headline;
        container-type: inline-size;
        text-align: center;

        h1{
            width: 60%;
            font-size: 25px;
            margin: auto;
        }

        @container headline (width > 700px) {
            h1 {
                font-size: 55px;
            }
        }
    }

    @container claims-qr (width > 700px) {
        .unique-game {
            font-size: 40px;
        }
    }

    .default{
        flex: 1;
        width: 100%;
        display: flex;
        flex-flow: column;
        height: 100%;
        justify-content: center;

        .selector{
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: space-between;
            //gap: 10px;
            //height: 100%;

            .asset{
                width: 60%;
                margin-right: 0.8rem;
                margin-left: 0.8rem;
            }

            .arrow{
                width: 11%;
                &.left{
                    margin-left: 1rem;
                }
                &.right{
                    margin-right: 1rem;
                }
            }
        }

        .asset-name{
            font-size: 2cap;
            color: #ffffff;
            font-family: Joystick, sans-serif;
            margin-top: 3%;
            container-name: asset-name;
            container-type: inline-size;

            spoan{
                font-size: 25px;
            }

        @container asset-name (width > 700px) {
            span {
                font-size: 45px;
            }
        }

        }

        .content{
            border: 1px black solid;
            width: 300px;
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .asset-img-container{
            height: 100%;
            width: fit-content;
            min-width: 60%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

        }
    }

    .game-name{
        flex: 1;
        display: flex;
        flex-flow: column;
        justify-content: center;
        text-align: center;

        .draw{
            width: 50%;
            margin-bottom: 15px;
            margin-left: auto;
            margin-right: auto;
        }

        .carousels{
            .carousel{
                display: flex;
                flex-flow: row;
                justify-content: center;
                align-items: center;
                container-name: carousel;
                container-type: inline-size;

                span{
                    flex-basis: 13rem;
                    color: #ffffff;
                    font-family: v-bind(selectedFont), sans-serif;
                    font-size: 24px;
                }

                @container carousel (width > 700px) {
                    span {
                        flex-basis: 25rem;
                        font-size: 40px;
                    }
                }

                img{
                    flex-basis: 8rem;
                    width: 30%;
                }
            }
        }

        .fonts{
            margin-top: 10%;
            padding-top: 20px;
            padding-bottom: 20px;
            width: 100%;
            background-color: #245375;
            display: flex;
            flex-flow: row;
            justify-content: center;
            gap: 5%;
            container-name: fonts;
            container-type: inline-size;

            .font{
                display: flex;
                flex-flow: column;
                align-items: center;
                font-size: 28px;
                color: #7591ab;
                gap: 5px;
                justify-content: space-between;
                &.selected{
                    color: #ffffff;
                }
                img{
                    width: 20%;
                }
            }

            @container fonts (width > 700px)  {
                .font{
                    font-size: 45px;
                }
            }
        }
    }

</style>
